<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col lg="8" class="d-none d-lg-flex">
        <div class="w-100 d-lg-flex justify-content-center">
          <div id="login-intro" style="flex: 1 1 0%; box-sizing: border-box;">
            <div class="logo">
              <img class="ng-tns-c25-5" src="/assets/shield-white.svg">
            </div>
            <div class="title ng-trigger ng-trigger-animate">
              Forgot your <span class="ng-tns-c25-5" style="font-family: boxen-bold;">Secris</span> Password?
            </div>
            <div class="description ng-trigger ng-trigger-animate">
              Use the form to reset your password
            </div>
          </div>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            FORGOT PASSWORD? 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Enter your email and we'll send you instructions to reset your password
          </b-card-text>

          <!-- form -->
          <validation-observer ref="verifyValidation">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label="Email"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="email"
                    :state="errors.length > 0 ? false:null"
                    name="email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-alert v-model="showForgotErrors" variant="danger" dismissible>
                <div class="alert-body">
                  <span>{{ forgotError }}</span>
                </div>
              </b-alert>

              <b-button
                type="submit"
                variant="primary"
                block
              >
                Send reset link
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login-v2'}">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import auth from '@/auth/auth'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      forgotError: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {
    showForgotErrors() {
      return this.forgotError !== ''
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.verifyValidation.validate().then(success => {
        if (success) {
          auth.forgotPassword({
            email: this.email,
          }).then(() => {
            this.$router.replace('/auth/login')
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Check your inbox.',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: "We've sent you a password reset email!",
                  },
                })
              })
              .catch(error => {
                this.forgotError = error.response
              })
          }, error => {
            if (error.response) {
              this.forgotError = error.response.data.error
            } else {
              this.forgotError = error.message
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
